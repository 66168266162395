@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400&family=Nunito+Sans:ital,opsz@0,6..12;1,6..12&family=Poppins:wght@100;200&family=Raleway:ital,wght@0,100;1,100&family=Work+Sans:wght@100;200;400&display=swap');


.marquee-container {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-content {
  animation: marquee 10s linear infinite;
}

.no-border:focus {
  box-shadow: none !important;
  outline: none;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}